























































































































































// ? dialog style
.add_logs_dialog_user {
  .el-dialog {
    @media (min-width: 320px) and (max-width: 767px) {
      width: 90% !important;
    }
  }
}
.main_logs_wrapping_container {
  .el-table .cell {
    word-break: break-word;
    white-space: normal;
  }
}
